import { DEFAULT_TIME_FORMAT_12 } from "constants/dateTime";

export const BATCH_MAP_TYPES = {
  NO_CHANGE: 0,
  UPDATE: 1,
  NEW_BATCH: 2,
};

export const weekDaysZeroBased = [0, 1, 2, 3, 4, 5, 6];

export const TIME_FORMAT = DEFAULT_TIME_FORMAT_12;

export const courseDurationType = {
  FIXED: 1,
  ONGOING: 2,
};
export const courseDurationTypeOptions = [
  {
    id: 1,
    value: courseDurationType.FIXED,
    label: "Fixed",
  },
  {
    id: 2,
    value: courseDurationType.ONGOING,
    label: "Ongoing",
  },
];

export const subscriptionDateType = {
  CUSTOM_DATE: 1,
  RELATIVE_DATE: 2,
};
export const subscriptionDateTypeOptions = [
  {
    id: 1,
    value: subscriptionDateType.CUSTOM_DATE,
    label: "Custom Date",
  },
  {
    id: 2,
    value: subscriptionDateType.RELATIVE_DATE,
    label: "From the date of booking",
  },
];

export const courseLengthType = [
  {
    id: 1,
    value: 1,
    label: "Weeks",
  },
  {
    id: 2,
    value: 2,
    label: "Months",
  },
  {
    id: 3,
    value: 3,
    label: "Sessions",
  },
];

export const courseEndChoices = {
  week: 1,
  month: 2,
  session: 3,
};

export const DEFAULT_MAX_SPOTS = 100000;

export const CLASSES_LEARN_MORE_HREFS = {
  REGULAR_CLASS_CREATION:
    "https://support.exlyapp.com/support/solutions/articles/84000347504-regular-classes-on-exly-how-to-create-and-share-them-",
  FLEXIBLE_CLASS_CREATION:
    "https://support.exlyapp.com/support/solutions/articles/84000347508-flexible-classes-on-exly-how-to-create-and-share-them-",
};
